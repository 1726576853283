/* write your css here */

@tailwind base;
@tailwind components;
@tailwind utilities;

html ,body{
    overflow-x: hidden;
	scroll-behavior: smooth;
box-sizing: border-box;
}

.box-shadow-top-30px-20px {
    box-shadow: 0px -20px 20px rgba(58, 21, 132, 0.2);
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
  }

 .navlink a.active{
    color: yellow;
 }
 .tabCourse a.active{
	color: yellow;
	position: relative;

 }
 .tabCourse a.active:after{
	content: "";
	width: 100%;
	height: 1px;
	background-color: yellow;
	position: absolute;
	bottom: -9px;
	
 }


 @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .text-gradient {
    background: linear-gradient(45deg, #f7630c, #e643fb, #1cc7d0);
    background-size: 200% auto;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradient-animation 3s linear infinite;
  }
  
  .text-highlight {
    display: inline-block;
  }
  

  /* //spinner */
  .loader {
	width: 48px;
	height: 48px;
	border: 3px solid #FFF;
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	position: relative;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
  }
  .loader::after {
	content: '';  
	position: absolute;
	box-sizing: border-box;
	left: 20px;
	top: 31px;
	border: 10px solid transparent;
	border-right-color: #FFF;
	transform: rotate(-40deg);
  }
  
  @keyframes rotation {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  } 

.form-style {
    @apply rounded-lg bg-richblack-700 p-3 text-[16px] leading-[24px] text-richblack-5 shadow-[0_1px_0_0] shadow-white/50 placeholder:text-richblack-400 focus:outline-none;
  }

ul,li{
padding: 0;
margin: 0;
}
  


.codeblock1 {
	width: 372.95px;
	height: 257.05px;
	border-radius: 100%;
	left: calc(50% - 372.95px / 2 - 76.53px);
	top: calc(50% - 257.05px / 2 - 47.47px);
	background: linear-gradient(
	  123.77deg,
	  #8a2be2 -6.46%,
	  #ffa500 59.04%,
	  #f8f8ff 124.53%
	);
	opacity: 0.2;
	filter: blur(34px);
	transform: matrix(1, 0, -0.03, 1, 0, 0);
  }
  .codeblock2 {
	position: absolute;
	width: 372.95px;
	height: 257.05px;
	left: calc(50% - 372.95px / 2 - 76.53px);
	top: calc(50% - 257.05px / 2 - 47.47px);
	border-radius: 100%;
	background: linear-gradient(
	  118.19deg,
	  #1fa2ff -3.62%,
	  #12d8fa 50.44%,
	  #a6ffcb 104.51%
	);
	opacity: 0.2;
	filter: blur(34px);
	transform: matrix(1, 0, -0.03, 1, 0, 0);
  }
  .code-border {
	border: 2px solid;
	border-image-slice: 1;
	border-width: 2px;
	border-image-source: linear-gradient(to right bottom, #ffffff38, #ffffff00);
	background: linear-gradient(
	  111.93deg,
	  rgba(14, 26, 45, 0.24) -1.4%,
	  rgba(17, 30, 50, 0.38) 104.96%
	);
	/* backdrop-filter: blur(26px); */
  }

  .logo{
	filter: drop-shadow(6px 6px 6px #091072);
  }



  /* Add this to your CSS */
/* Add this to your CSS */
.animate-border {
	animation: borderAnimation 2s ease-in-out  reverse;
  }
  
  @keyframes borderAnimation {
	0%, 100% {
	  height: 0;
	}
	50% {
	  height: 100%;
	}
  }
  
  